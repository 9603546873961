<template>
    <div>
        <table class="table table-borderless">
            <thead>
                <tr>
                  <th scope="col" width="40%">Artikel</th>
                  <th scope="col" width="11%">Einzelpreis</th>
                  <th scope="col" width="11%">Anzahl</th>
                  <th scope="col" width="27%">MwSt.</th>
                  <th class="text-right" scope="col" width="11%">Gesamt</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td scope="col">
                        <span class="article">Geschenkgutschein</span>
                    </td>
                    <td scope="col">{{total}} €</td>
                    <td scope="col">1</td>
                    <td scope="col">19 %</td>
                    <td class="text-right" scope="col">{{total}} €</td>
                </tr>
                <tr class="border-top">
                    <td class="text-center" scope="col"></td>
                    <td class="text-center" scope="col"></td>
                    <td class="text-right" scope="col" colspan="2">Summe Artikel (netto):</td>
                    <td class="text-right" scope="col">{{totalWithoutTax}} €</td>
                </tr>
                <tr>
                    <td scope="col"></td>
                    <td scope="col"></td>
                    <td class="text-right" scope="col" colspan="2">zzgl. MwSt. 19 %:</td>
                    <td class="text-right" scope="col">{{totalTax}} €</td>
                </tr>
                <tr>
                    <td scope="col"></td>
                    <td scope="col"></td>
                    <td class="text-right" scope="col" colspan="2">Summe Artikel (brutto):</td>
                    <td class="text-right" scope="col">{{total}} €</td>
                </tr>
                <tr>
                    <td scope="col"></td>
                    <td scope="col"></td>
                    <td class="text-right border-top" scope="col" colspan="2"><strong>Gesamtsumme:</strong></td>
                    <td class="text-right border-top" scope="col"><strong>{{total}} €</strong></td>
                </tr>
            </tbody>
        </table>
    </div>
</template>
<script>

    export default {
        name: 'CouponCartTable',
        data() {
            return {
                cart: this.$store.state.cart,
            }
        },
        computed: {
            total() {
                var val = this.cart.couponValue.replace(",", ".")
                return parseFloat(val)
            },
            totalTax(){
                return (this.total * (19/(100+19))).toFixed(2)
            },
            totalWithoutTax(){
                return this.total - this.totalTax
            },
        },
        methods: {
        },
    }
</script>
<style lang="scss" scoped>

    .table{
        thead{
            border-bottom: thin solid #000 !important;
            font-size: 21px;
        }

        tbody{

            td{
                font-size: 21px;
                font-weight: 400;

                .article{
                    font-size: 25px;
                    font-weight: 600;
                }
            }
        }
    }


</styles>