<template>
    <div>
        <div class="container-fluid blue-bg">
            <div class="row">
                <div class="col-md-5 col-lg-4 text-right">
                    <h3>Rechnungsadresse</h3>
                </div>
                <div class="col-md-7 col-xl-8">
                    <a href="booking_form" class="button black float-right">Ändern</a>
                </div>
            </div>
            <dl class="row" v-if="user_data.customer_type == 'company'">
                <dt class="col-md-5 col-lg-4 text-right">Firma:</dt>
                <dd class="col-md-6 offset-md-1">{{ user_data.company }}</dd>
            </dl>
            <dl class="row" v-if="user_data.customer_type == 'company'">
                <dt class="col-md-5 col-lg-4 text-right">USt.-Nr.:</dt>
                <dd class="col-md-6 offset-md-1">{{ user_data.company_tax_number }}</dd>
            </dl>
            <dl class="row">
                <dt class="col-md-5 col-lg-4 text-right">Name, Vorname:</dt>
                <dd class="col-md-6 offset-md-1">{{ user_data.title }} {{ user_data.lastname }}, {{ user_data.firstname }}</dd>
            </dl>
            <dl class="row">
                <dt class="col-md-5 col-lg-4 text-right">Straße/Nr.:</dt>
                <dd class="col-md-6 offset-md-1">{{ user_data.street }}</dd>
            </dl>
            <dl class="row">
                <dt class="col-md-5 col-lg-4 text-right">PLZ/Ort:</dt>
                <dd class="col-md-6 offset-md-1">{{ user_data.zipcode }} {{ user_data.city }}</dd>
            </dl>
            <dl class="row">
                <dt class="col-md-5 col-lg-4 text-right">Telefon:</dt>
                <dd class="col-md-6 offset-md-1">+49 {{ user_data.phone }}</dd>
            </dl>
            <dl class="row">
                <dt class="col-md-5 col-lg-4 text-right">E-Mail:</dt>
                <dd class="col-md-6 offset-md-1">{{ user_data.mail }}</dd>
            </dl>
        </div>
    </div>
</template>
<script>

    export default {
        name: 'CustomerDetails',

        components: {
        },
        data() {
            return {
                user_data: this.$store.state.user_data.user_data
            }
        }
    }
</script>
<style lang="scss" scoped>

    .container-fluid{
        padding-left:140px;
        padding-right:140px;
        padding-top: 90px;

        @media(max-width: 768px) {
            padding-right: 15px;
                padding-left: 15px;
        }

        dt{
            font-weight: 600;
        }

        dd{
            font-weight: 400;;
        }

        dt,
        dd{
            font-size: 21px;
            letter-spacing: 0.53px;
            line-height: 20px;
        }
    }


</styles>