<template>
    <div>
        <table class="table table-borderless">
            <thead>
                <tr>
                  <th scope="col" width="40%">Artikel</th>
                  <th scope="col" width="11%">Einzelpreis</th>
                  <th scope="col" width="11%">Anzahl</th>
                  <th scope="col" width="27%">MwSt.</th>
                  <th class="text-right" scope="col" width="11%">Gesamt</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td scope="col">
                        <span class="article">Reservierungskosten</span>
                        <br>Bei der Buchung erfolgt eine Anzahlung in Höhe von 39,90 € vom Gesamtbetrag. {{ totalText}} Sie können ihre Buchung kostenlos bis zu 24 Stunden vor Terminbeginn unter <a href="mailto:storno@waschprofis.de">storno@waschprofis.de</a> stornieren.
                    </td>
                    <td scope="col">39,90 €</td>
                    <td scope="col">1</td>
                    <td scope="col">19 %</td>
                    <td class="text-right" scope="col">39,90 €</td>
                </tr>
                <tr class="border-top">
                    <td class="text-center" scope="col"></td>
                    <td class="text-center" scope="col"></td>
                    <td class="text-right" scope="col" colspan="2">Summe Artikel (netto):</td>
                    <td class="text-right" scope="col">33,57 €</td>
                </tr>
                <tr>
                    <td scope="col"></td>
                    <td scope="col"></td>
                    <td class="text-right" scope="col" colspan="2">zzgl. MwSt. 19 %:</td>
                    <td class="text-right" scope="col">6,38 €</td>
                </tr>
                <tr>
                    <td scope="col"></td>
                    <td scope="col"></td>
                    <td class="text-right" scope="col" colspan="2">Summe Artikel (brutto):</td>
                    <td class="text-right" scope="col">39,90 €</td>
                </tr>
                <tr>
                    <td scope="col"></td>
                    <td scope="col"></td>
                    <td class="text-right border-top" scope="col" colspan="2"><strong>Gesamtsumme:</strong></td>
                    <td class="text-right border-top" scope="col"><strong>39,90 €</strong></td>
                </tr>
            </tbody>
        </table>
    </div>
</template>
<script>

    export default {
        name: 'CartTable',
        props: ['coupon'],
        data() {
            return {
                car_type: this.$store.state.cart.product.split("_")[0],
                cleaning_type: this.$store.state.cart.product.split("_")[1],
                additional_service: this.$store.state.cart.additional_service,
                cart: this.$store.state.cart,
                actionWeek: false
            }
        },
        computed: {
          items() { return this.$store.state.items },
          totalText(){
              var cleaning = this.items[this.car_type][this.cleaning_type].price,
                  additional_service = this.items[this.car_type][this.cleaning_type].additional_service,
                  additional = 0,
                  hasCaramic = false;

              if(this.additional_service.length > 0){
                  this.additional_service.forEach(value=>{
                      additional += additional_service[value].price;
                      if(value === "Keramikversiegelung"){
                            hasCaramic = true;
                          }
                  })
              }

              var price = parseFloat(cleaning + additional);

              if(this.coupon){
                if(this.coupon.indexOf("%") !== -1){
                    price = price - ((price * this.coupon.replace('%', '') ) / 100)
                }else{
                    price = parseFloat(price - this.coupon).toFixed(2)
                }
              }

              if(this.actionWeek && this.cleaning_type === "outside" && hasCaramic){

                price = (price * 75 ) / 100;
              }

              price = parseFloat(price - 39.90).toFixed(2);

              if(price < 0) {
                return `Diese wird Ihnen bei der Abholung ihres Fahrzeuges erstattet.`;
              }
              return `Die Restsumme (${price}) € wird bei Abholung ihres Fahrzeugs fällig.`;
          },
        },
        mounted() {
            if(new Date() < new Date('August 15, 2021 23:59:59')){
                this.actionWeek = true;
            }
        }
    }
</script>
<style lang="scss" scoped>

    .table{
        thead{
            border-bottom: thin solid #000 !important;
            font-size: 21px;
        }

        tbody{

            td{
                font-size: 21px;
                font-weight: 400;

                .article{
                    font-size: 25px;
                    font-weight: 600;
                }
            }
        }
    }


</styles>