<template>
    <div>
        <Header></Header>
        <div class="container-fluid">
            <div class="row">
                <h2 class="col-xl-4 offset-xl-4 text-center">Ihre Buchung bei<br> waschprofis</h2>
            </div>
        </div>
        <CartDetails v-if="cart.product != 'coupon'"></CartDetails>
        <CouponCartDetails v-if="cart.product == 'coupon'"></CouponCartDetails>
        <CustomerDetails></CustomerDetails>
        <div class="container-fluid grey-bg">
            <div class="row">
                <div class="col-md-5 col-lg-4 text-right">
                    <h3>Zahlung</h3>
                </div>
                <div class="col-md-6 offset-md-1">
                    <form>
                        <div class="form-check payment-list-entry">
                            <input type="radio" value="sofort" name="payment" id="sofort" class="form-check-input" v-model="paymentType">
                            <label for="sofort" class="form-check-label">
                                <img src="https://www.mollie.com/external/icons/payment-methods/sofort.svg"> SOFORT Überweisung
                            </label>
                        </div>
                        <div class="form-check payment-list-entry">
                            <input type="radio" value="giropay" name="payment" id="giropay" class="form-check-input" v-model="paymentType">
                            <label for="giropay" class="form-check-label">
                                <img src="https://www.mollie.com/external/icons/payment-methods/giropay.svg"> Giropay
                            </label>
                        </div>
                        <div class="form-check payment-list-entry">
                            <input type="radio" value="creditcard" name="payment" id="creditcard" class="form-check-input" v-model="paymentType">
                            <label for="creditcard" class="form-check-label">
                                <img src="https://www.mollie.com/external/icons/payment-methods/creditcard.svg"> Kreditkarte
                            </label>
                        </div>
                        <div class="form-check payment-list-entry">
                            <input type="radio" value="paypal" name="payment" id="paypal" class="form-check-input" v-model="paymentType">
                            <label for="paypal" class="form-check-label">
                                <img src="https://www.mollie.com/external/icons/payment-methods/paypal.svg"> PayPal
                            </label>
                        </div>
                    </form>
                </div>
                <div class="col-xl-6 offset-xl-3 blue-bg coupon" v-if="cart.product != 'coupon'">
                    <strong>Gutschein einlösen? </strong>
                    <input type="text" placeholder="Gutscheincode eintragen" v-model="cart.coupon">
                    <a @click.prevent="checkCoupon" class="button blue">Einlösen</a>
                </div>
                <div class="col-xl-6 offset-xl-3 coupon-response" v-if="couponText">
                    <div v-html="couponText"></div>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <div class="white-bg">
                        <CartTable :coupon="coupon" v-if="cart.product != 'coupon'"></CartTable>
                        <CouponCartTable v-if="cart.product == 'coupon'"></CouponCartTable>
                    </div>
                </div>
                <div class="col-12 text-right agb-check" :class="{ 'form-group--error': $v.checked.$error }">
                    <div class="form-check form-check-inline">
                          <input class="form-check-input" type="checkbox" name="checked" id="checked" v-model="checked"  :class="{ 'is-invalid': $v.checked.$error }" v-model.trim="$v.checked.$model"  @change="$v.checked.$touch()" required>
                          <label class="form-check-label" for="checked">Ich erkläre mich mit den <router-link to="terms" target= '_blank'>allgemeinen Geschäftsbedingungen</router-link> und Bestimmungen zum <router-link to="privacy" target= '_blank'>Datenschutz</router-link> einverstanden.</label>
                    </div>
                    <div class="error" v-if="!$v.checked.sameAs">Sie müssen die allgemeinen Geschäftsbedingungen und die Datenschutz erklärung akzeptieren.</div>
                </div>
                <div class="col-8 offset-2 text-center" v-if="error">
                    <b-alert show variant="danger">
                        Ihre Zahlung ist fehlgeschlagen, oder wurde abgebrochen!
                    </b-alert>
                </div>
                <div class=" col-12 text-right">
                    <div class="form-group">
                        <button @click.prevent="prepareOrder()" class="button invert" :disabled="loading">
                            <span v-html="CheckOutText"></span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <Footer></Footer>
    </div>
</template>
<script>
    import CartDetails from "@/components/CartDetails.vue";
    import CouponCartDetails from "@/components/CouponCartDetails.vue";
    import CustomerDetails from "@/components/CustomerDetails.vue";
    import CartTable from "@/components/CartTable.vue";
    import CouponCartTable from "@/components/CouponCartTable.vue";
    import Header from "@/components/Header.vue";
    import Footer from "@/components/Footer.vue";

    import axios from 'axios';

    import { sameAs } from 'vuelidate/lib/validators'

    export default {
        name: 'CheckOut',

        components: {
            CartDetails,
            CouponCartDetails,
            CustomerDetails,
            CartTable,
            CouponCartTable,
            Header,
            Footer
        },
        data() {
            return {
                error: this.$store.state.error,
                payment_methods: {

                },
                user_data: this.$store.state.user_data.user_data,
                cart: this.$store.state.cart,
                CheckOutText: 'Zahlungspflichtig bestellen',
                CheckOutUrl: '',
                loading: false,
                couponText: '',
                paymentType: '',
                checked: false,
                coupon: false,
            }
        },
        watch: {
            paymentType(){
                this.CheckOutText = 'Zahlungspflichtig bestellen';
                this.loading = false;
            }
        },
        methods: {
            prepareOrder() {
                this.$v.$touch()
                if (this.$v.$invalid) {
                    this.submitStatus = 'ERROR'
                } else {
                    this.loading =  true
                    this.CheckOutText = "Bitte warten"
                    axios.interceptors.request.use(function(config) {
                        var container = document.querySelector('#app');
                        var loader = document.querySelector('.loader');
                        if(container && !loader){
                            var temp = `
                            <center><br><br><br>
                            <div class="loader" id="loader"></div>
                            <div class="loader" id="loader2"></div>
                            <div class="loader" id="loader3"></div>
                            <div class="loader" id="loader4"></div>
                            <span id="text">Sie werden zu unserem Zahlungsanbieter weiter geleitet</span><br>
                            </center>
                            `;

                            // document.querySelector('#app > div').style.display = 'none';
                            container.innerHTML = temp + container.innerHTML;
                        }
                        return config
                    }, function(error) {
                        this.loading =  false;
                        document.querySelector('#app').removeChild(document.querySelector('center'));
                        this.CheckOutText = "Es ist ein Fehler aufgetreten"
                        return Promise.reject(error);
                    });

                    if(this.paymentType){
                    axios
                        .post(`https://api.opel-dresden.de/api/payment/prepare`, { "method": this.paymentType, "user_data": this.user_data, "cart": this.cart, 'shipping_address': this.$store.state.user_shipping_data.user_shipping_data}, {
                        })
                        .then(response => {
                            this.CheckOutUrl = response.data.data;
                            this.cart.order = response.data.data.split("/").pop()
                        })
                        .catch(function(error) {
                            console.log(error);
                        }).finally(() => {
                            this.loading =  false
                            window.location = this.CheckOutUrl;
                        });
                    }else{
                        this.CheckOutText = "Bitte wählen Sie ein Zahlungsmethode aus.";
                    }
                }
            },
            checkCoupon(){
                axios
                    .post(`https://api.opel-dresden.de/api/check_coupon`, { "coupon": this.cart.coupon}, {
                    })
                    .then(response => {
                        if(!response.data.data){
                            this.couponText = "Ungültiger Gutscheincode!"
                        }else {
                            if(response.data.data.car_rental){
                                this.couponText += "Sie erhalten für den Zeitraum der Reinigung einen kostenlosen Mietwagen.<br>"
                            }
                            if(response.data.data.value.indexOf("%") !== -1){
                                this.couponText += `Sie erhalten einen Rabatt im Wert von ${response.data.data.value}<br>`
                                this.coupon = response.data.data.value
                            }else if(response.data.data.value !== ""){
                                this.couponText += `Sie erhalten einen Rabatt im Wert von ${response.data.data.value} €<br>`
                                this.coupon = response.data.data.value
                            }
                        }
                    })
                    .catch(function(error) {
                        console.log(error);
                    });
            },
        },
        validations () {
            return {
                checked: {
                    sameAs: sameAs( () => true )
                },
            }
        }
    }
</script>
<style lang="scss" scoped>

    .agb-check{
        margin-bottom: 35px;
    }

    .white-bg{
        background-color: #FFF;
        margin-top: 65px;
        margin-bottom: 35px;
        padding: 65px 125px;

        @media(max-width: 1024px) {
            padding: 55px;
        }

        @media(max-width: 768px) {
            padding: 15px;
        }

        .table{
            thead{
                border-bottom: thin solid #000 !important;
                font-size: 21px;
            }

            tbody{

                td{
                    font-size: 21px;
                    font-weight: 400;

                    .article{
                        font-size: 25px;
                        font-weight: 600;
                    }
                }
            }
        }
    }

    .form-group--error .error {
        margin-top: 10px;
        display: inline-block;
        color: #dc3545;
    }
    .error {
        font-size: 0.85rem;
        line-height: 1;
        display:none;
        margin-bottom:20px
    }

    .blue-bg.coupon{
        padding:45px;
        font-size:21px;
        margin-bottom:0;

        input{
            height:65px;
            width:300px;
            margin-left: 25px;
            margin-right: 25px;
            border: none;
            padding: 0.375rem 0.75rem;
        }
    }

    .coupon-response{
        font-size:21px;
        padding: 20px 45px;
        background-color:#456FEF;
        color:#FFF;
        font-weight:600;
    }

    form{

        .payment-list-entry{
            font-size:21px;
            padding-left:0;

            input{
                margin-top: .75rem;
            }

            label{
                padding-bottom:40px;
                font-family: 'futura-pt';

                img{
                    margin-right:15px;
                }
            }

            input:checked+label{
                font-family: 'futura-pt-bold';
                img{
                    border: thin solid #e3eafd;
                }
            }
        }

        .form-control{
            height: 65px;
            border: none;
        }
    }


</styles>